import * as Sentry from "@sentry/react";
import { useUnknownErrorModal } from "./Modal/BasicDialogContent";




// type BaseHandler = {
//     [type: string]: (msg: string) => void;
// };

// type HandlersWithSuccessAndUnhandled = {
//     success: (response: JSON) => void;
//     unhandled: UnhandledFallbackAction;
// } & BaseHandler;

// type Handlers = 
//     | HandlersWithSuccessAndUnhandled 
//     | BaseHandler;



export const useFetch = () => {
    const error = useUnknownErrorModal();


    /**
     * Vanilla fetch wrapper with sentry metrics and logging + error handling.
     * @param url Url.  Passed straight to fetch()
     * @param options Method, body, etc.  Passed straight to fetch()
     * @param handlers Success and error handlers.
     * If no success handler is included, it is assumed to be a background task and will fail silently, unless an unhandled then it will show a modal
     * Success anyway would be used for like setting email on kiosk.  We want to see if there are any input errors to show email conflicts, but if any weird errors or fail to fetch them just say success.
     * Dismiss would be used for like saving changes made to projects in the dashboard.  Users will have to just try again.  Changes should not be cleared from the ui tho.
     * Reload Button would be used for like loading dashboard info.  Users will just have to reload and see if it works.
     * Auto reload would be used for loading display or project data on kiosk.  It should show an error in the meantime and then reload without interaction.
     * Kiosk restart would be used to navigate back to the kiosk welcome page.
     */
    const fetchData = async (
        url: string,
        options: any,
        handlers: any, // TODO type
        retryNo: number = 0
    ) => {


        // Fetch
        const response = await fetch(url, options)


        // Failed to fetch
        .catch(() => {

            console.log("Failed to fetch error!  Current retries " + retryNo)

            // Metric to keep track of failed to fetch errors.
            Sentry.metrics.increment("failed_to_fetch", 1); // TODO include client details (unless sentry does this already) (identify kiosks with weak connection)

            // Auto retry
            if (retryNo >= 3) { // Finnaly fail (after 3 retries) :(
                console.log("-> Given up.")

                // Message to actually alert and have additional details + replay.
                Sentry.captureMessage(`Failed To Fetch (after retries) | Url: (${url})`);

                // Success anyway (silent fail)
                if (handlers.unhandled == "success_anyway" && handlers.success) handlers.success();

                // Show error modal
                else if (handlers.unhandled) {
                    error(
                        handlers.unhandled,
                        {
                            type: "failed_fetch",
                            url: url
                        }
                    )
                }

            }

            // Retry again
            else {
                console.log("-> Going to retry...")
                setTimeout(() => {
                    console.log("-> Retrying...")
                    fetchData(url, options, handlers, retryNo + 1)
                }, (retryNo + 1) * 1000) // Exponential backoff (+1 so it doesnt *0)
                // TODO some way to say (taking longer than expected) or smth?
            }

            return;

        })


        // Success
        if (response!.status == 200) {
            if (handlers.success) handlers.success(response!);
        }


        // Input error
        else if (response!.status == 400) {
            const json = await response!.json();

            // Handled input error
            if (handlers["input_"+json.error.type]) {

                // Metric to keep track of normal and expected errors.  These are not an issue unless there is a weird spike.
                Sentry.metrics.increment("handled_input_error", 1, {
                    tags: { type: json.error.type },
                });

                // Return to handler
                handlers["input_"+json.error.type](json.error.message);
            }

            // Unhandled input error
            else {

                // Message to actually alert and have additional details + replay.
                Sentry.captureMessage(`Unhandled Input Error | Type: (${json.error.type}) | Msg: (${json.error.message})`);

                // Success anyway (silent fail)
                if (handlers.unhandled == "success_anyway" && handlers.success) handlers.success();

                // Show error modal
                else if (handlers.unhandled) {
                    error(
                        handlers.unhandled,
                        {
                            type: "input",
                            cat: json.error.type,
                            msg: json.error.message
                        }
                    )
                }

            }
        }

        
        // Unknown error
        else {

            const text = await response!.text();
            
            // Message to actually alert and have additional details + replay.
            Sentry.captureMessage(`Unhandled Code Error | Code: (${response!.status}) | Url: (${url}) | Msg: (${text})`);

            // Success anyway (silent fail)
            if (handlers.unhandled == "success_anyway" && handlers.success) handlers.success();

            // Show error modal
            else if (handlers.unhandled) {
                error(
                    handlers.unhandled,
                    {
                        type: "code",
                        code: response!.status,
                        text: text
                    }
                )
            }

        }
    
    };

    return fetchData;

}