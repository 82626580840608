import axios from "axios";
import { DateTime, Interval, Settings } from "luxon";
import { useEffect } from "react";
import siteConfig from "../site-config.json";


Settings.defaultZone = "America/Los_Angeles";


export function getPrevMidnight(ms: number) {
    let dateTime: DateTime;
    if (ms) dateTime = DateTime.fromMillis(ms);
    else dateTime = DateTime.now();
    return dateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toMillis();
}


export function floor(ts: DateTime) {
    return ts.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
}


/**
 * Returns a dynamic date and time string
 */
export function getPrettyDateStr(time: number) {

    // Check if within 60 min
    if (DateTime.fromMillis(time) > DateTime.now().plus({ hour: -1 })) {
        const mins = Math.round(Interval.fromDateTimes(DateTime.fromMillis(time), DateTime.now()).length("minutes"));

        if (!mins || isNaN(mins) || mins == 0) {
            return "Just now";
        }
        else if (mins == 1) {
            return "1 minute ago"
        }
        else {
            return mins + " minutes ago"
        }
    }

    const inputDate = DateTime.fromMillis(time).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const currentDate = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const tomorrow = currentDate.plus({ day: 1 });

    const yesterday = currentDate.plus({ day: -1 });

    if (inputDate.toMillis() == currentDate.toMillis()) {
        return "Today at " + getTimeStr(time);
    }

    if (inputDate.toMillis() == tomorrow.toMillis()) {
        return "Tomorrow at " + getTimeStr(time);
    }

    if (inputDate.toMillis() == yesterday.toMillis()) {
        return "Yesterday at " + getTimeStr(time);
    }

    return getDateStr(time) + " at " + getTimeStr(time);
}


/**
 * Returns a date in this format:
 * "4/18/24"
 */
export function getDateStr(time: number) {
    return DateTime.fromMillis(time).toFormat("L/d/yy")
}


export function capitalizeFirstLetter(string: string | null | undefined) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}


/**
 * Returns a time in this format:
 * "4 PM" or if minutes are set, "4:03 PM"
 */
export function getTimeStr(time: number) {
    const now = DateTime.fromMillis(time);

    const hour = now.hour;
    const ampm = hour >= 12 ? "PM" : "AM";

    let hours = hour % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let minutes: string | number = now.minute;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    if (minutes == '00') return `${hours} ${ampm}`;
    else return `${hours}:${minutes} ${ampm}`;
}


/**
 * Returns a date string in format "January 15th, 2024"
 */
export function getDateLongStr(time: number) {
    return DateTime.fromMillis(time).toFormat("MMMM d") + digitTailString(DateTime.fromMillis(time).day) + ", " + DateTime.fromMillis(time).year;
}


/**
 * Returns a time window in format "3 - 5 PM"
 */
export function getTimeWindowStr(time: number, hoursLength: number) {
    return getTimeStr(time) + " - " + getTimeStr(DateTime.fromMillis(time).plus({ hours: hoursLength }).toMillis());
}


/**
 * Returns a 2 charcter string (th, st, nd, rd) to be appended to the end of a number.
 * @param {*} digit The digit.
 * @returns The string.
 */
export function digitTailString(digit: number) {
    if (digit > 3 && digit < 21) return 'th';
    switch (digit % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
}


export async function uploadCloudinaryImage(file, preset, publicId, progressCallback) {

    const response = (await (await fetch(`/api/upload/sign?public_id=${publicId}&upload_preset=${preset}`)).json());


    const formData = new FormData();
    formData.append("file", file);
    formData.append("api_key", "779244834393219");
    formData.append("public_id", publicId);
    formData.append("timestamp", response.timestamp);
    formData.append("signature", response.signature);
    formData.append("upload_preset", preset);


    await axios.put("https://api.cloudinary.com/v1_1/drimvo8rp/image/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        method: "POST",
        onUploadProgress: (progressEvent) => {
            if (!progressEvent.total) return;
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            if (progressCallback) progressCallback(progress);
        }
    });

}


/**
 * This hook is only to be used to add additonal details to the already set (via middleware) title.
 */
export function useTitle(title) {
    useEffect(() => {
        const prevTitle = document.title;
        document.title = title + " | " + siteConfig.name;
        return () => {
            document.title = prevTitle;
        }
    })
}


export type UnhandledFallbackAction = "success_anyway" | "dismiss" | "reload_btn" | "auto_reload_5s" | "kiosk_restart";



export interface CustomField {
    id: string,
    reliesOn?: {
        fieldId: string, values: string[]
    }
}


// If the relies on field is not even here, then always show it
// This is to prevent config errors AND if a relies on field is added, and the one it relies on has
// already been set before, it will show (since its an unset field)
export function isFieldHidden(fieldOptions: CustomField, allFields: CustomField[], allFieldValues: { [key: string]: string }) {
	if (!fieldOptions.reliesOn) return false; // Doesn't rely, always show
	if (!allFields.some(f => f.id == fieldOptions.reliesOn!.fieldId)) return false; // Parent (relied on) field is not present, always show
	if (fieldOptions.reliesOn.values.includes(allFieldValues[fieldOptions.reliesOn.fieldId])) return false; // Matches, show
	return true; // Else hide
}