import ReactDOM from 'react-dom/client';
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import NotFoundPage from './pages/404/NotFoundPage';

import "./global/global.scss";

import ErrorModalContextProvider from './global/ErrorModal/ErrorModalContextProvider';
import GenericModalContextProvider from './global/GenericModal/GenericModalContextProvider';

import BannerContextProvider from './global/Banner/BannerContextProvider';

import "./global/modal.scss";
import { lazy } from 'react';
import CalendarEmbedWrapper from './pages/embeds/calendar/CalendarEmbedWrapper';

import * as Sentry from "@sentry/react";
import ModalContextProvider from './global/Modal/ModalContextProvider';

const KioskApp = lazy(() => import('./apps/KioskApp'));
const DisplayApp = lazy(() => import('./apps/DisplayApp'));
const DashboardApp = lazy(() => import('./apps/DashboardApp'));
const PortalApp = lazy(() => import('./apps/PortalApp'));

function App() {
  return (
    <BrowserRouter>

      <BannerContextProvider>

        <ModalContextProvider>

          <GenericModalContextProvider>

            <ErrorModalContextProvider>

              <Routes>

                <Route path="/kiosk/*" element={<KioskApp/>}/>

                <Route path="/display/*" element={<DisplayApp/>}/>

                <Route path="/portal/*" element={<PortalApp/>}/>

                <Route path="/dashboard/*" element={<DashboardApp/>}/>

                <Route path="/register/*" element={<Navigate to="/portal/register"/>}/>

                <Route path='/embeds'>

                  <Route path='calendar' element={<CalendarEmbedWrapper/>}/>

                </Route>

                <Route path='*' element={<NotFoundPage/>}/>

              </Routes>

            </ErrorModalContextProvider>

          </GenericModalContextProvider>

        </ModalContextProvider>

      </BannerContextProvider>

    </BrowserRouter> 
  )
}


if (import.meta.env.MODE == "production") {
  Sentry.init({
    dsn: "https://3cd2cd220704a3843f0d2b8fa59e0f30@o4506899508953088.ingest.us.sentry.io/4506899522650112", // TODO move to env
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [window.location.origin],
        networkCaptureBodies: true
      }),
      Sentry.feedbackIntegration({
        autoInject: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^\//],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}


ReactDOM.createRoot(document.getElementById('root')).render(<App/>);